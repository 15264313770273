import React from 'react'
import Home from './Tree/Home'

export default function Tree() {
  return (
    <div >
      <Home />
    </div>
  )
}
