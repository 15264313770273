import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    treeData:'',
    underuser:"",
    clickside:""
}
const TreeSlice = createSlice({
    name: "treeSlice",
    initialState,
    reducers: {
        setTreeData: (state, action) => {
            state.payment = action.payload
        },
        underuser: (state, action) => {
            state.underuser = action.payload;
          },
          clickside: (state, action) => {
            state.clickside = action.payload;
          },

    }
})

    export const {clickside,underuser} = TreeSlice.actions;
    export default TreeSlice.reducer;