
import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import IncomeHistory from 'views/AdminElements/IncomeHistory';
import UsersInitialPayment from 'views/AdminElements/UsersInitialPayment';
import WithdrawalRequest from 'views/AdminElements/WithdrawalRequest';
import Join from 'views/Elements/Join';
import UserDetails from 'views/AdminElements/UserDetails';
import AdminDashboardRoute from 'views/AdminElements/AdminDashboard';
import Sidebar from './Sidebar';
import Tree from 'views/Elements/Tree';
import ViewAll from 'views/AdminElements/ViewAll';

const SidebarAdmin = ({ mobileOpen, setMobileOpen }) => {

  return (
    <BrowserRouter>

      <Sidebar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen}>
        <Switch>
          <Route path="/" exact component={AdminDashboardRoute} />
          <Route path="/dashboard" exact component={AdminDashboardRoute} />
          <Route path='/user_initial_payment' exact component={UsersInitialPayment}/>
          <Route path='/tree' exact component={Tree}/>
          <Route path='/userDetails' exact component={UserDetails}/>
          <Route path='/ViewAll-Details' exact component={ViewAll}/>

          <Route path="/incomeHistory" exact component={IncomeHistory} />
          <Route path="/withdrawal" exact component={WithdrawalRequest} />
          <Route path="/joinUser" exact component={Join} />
          <Route path="/EditUser" exact component={Join} />


          <Redirect  from="/" to="/dashboard" />
        </Switch>
      </Sidebar>
    </BrowserRouter>
  )
}

export default SidebarAdmin
