import axios from 'axios'
import { API_URL } from 'config'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getItem } from 'utils/localStorageController'
import './index.css';
function Pay(props) {
  const [id, setId] = useState('')
  const [paymentId, setPaymentId] = useState('')
  const [orderId, setOrderid] = useState('')
  const [signature, setSignature] = useState('')
  const [showCongrats, setShowCongrats] = useState(false);
  const user = useSelector((state) => state.user)
  const userid = user.signIn.userid
  const a_token = getItem('a_token')
  const [success, setSuccess] = useState('')
  const [data, setData] = useState('');
  console.log(success, "rdatassssss")
  const paymentrequest = async () => {
    try {
      const response = await axios.post(API_URL + 'pages/manual_paymentrequest.php',
        {
          userid: userid
        },
        {
          headers: {
            Authorization: `Bearer ${a_token}`,
          },
        })
      if (response.data.status === true) {
        alert('AMOUNT 2500 PAY SUCCESSFULLY')
      }
    } catch (error) {
      console.error(error)
    }
  }
  const viewDetails = async () => {
    try {
      const response = await axios.get(API_URL + 'pages/user_details.php', {
        params: {
          userid: userid,
        },
        headers: {
          Authorization: `Bearer ${a_token}`,
        },
      });
      setData(response.data);
    } catch (error) {
      console.error('Error retrieving pin request:', error);
    }
  };
  useEffect(() => {
    viewDetails();
  }, []);
  const handleSubmit = async (e) => {
    setShowCongrats(true);

    e.preventDefault()
    var options = {
      key: 'rzp_test_PFKrEN7OTznunW',
      key_secret: 'QV6rjcE1q4eWVuKdz72KtzXv',

      amount: 250000,
      currency: 'INR',
      name: 'amazingproduct',
      email: 'gvivek548@gmail.com',
      order_id: id,
      prefill: {
        name: data.name,
        email: data.userid,
        contact: data.mobile,
      },
      notes: {
        address: 'Razorpay Corporate office',
      },
      theme: {
        color: '#3399cc',
      },
      handler: async function (response) {
        setPaymentId(response.razorpay_payment_id)

        setOrderid(response.razorpay_order_id)

        setSignature(response.razorpay_signature)
        try {
          const data = {
            razorpay_payment_id: response.razorpay_payment_id,
            order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
            userid: userid,
          }
          const payment = await axios.post(
            API_URL + 'razorpay/verify_payment_signature.php',
            data,
            {
              headers: {
                Authorization: `Bearer ${a_token}`,
              },
            }
          )
          if (payment.data.status === true) {
            setSuccess(payment.data)
            alert('Amount paid successFully')

            return
          }
          else {
            alert('Something went wrong.....try again')
          }
        } catch (error) {
          console.error('Error verifying payment signature:', error)
        }
      },
    }
    var rzp1 = new window.Razorpay(options)
    rzp1.on('payment.failed', function (response) {
      alert(response.error.code)
      alert(response.error.description)
      alert(response.error.source)
      alert(response.error.step)
      alert(response.error.reason)
      alert(response.error.metadata.order_id)
      alert(response.error.metadata.payment_id)
    })
    rzp1.open()
  }
  useEffect(() => {
    const response = axios.post(API_URL + 'razorpay/pay.php', {
      header: {
        Accept: "application/json",
        "Content-type": "multiple/form-data",
      },
    })
      .then((response) => ((setId(response.data.id))))
      .catch((error) => {
        console.error('Error:', error);
      });
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
  }, [])

  return (
    <div
      className="App d-flex justify-content-center align-items-center"
      style={{ minHeight: '100vh' }}
    >


      <div className="text-center">
        <h1 className="text-white">Razorpay Payment Process</h1>
        <button className='request_button text-white' onClick={paymentrequest} >Request access to the admin to pay manually<br />click here!!</button>
        {/* {success.status === true ? (
          <>
            <h2 style={{ color: 'white' }}>You Paid 2500 successfully</h2>
            {showCongrats && (
              <div className="congrats-container">
                <div className="congrats">Congratulations!</div>
              </div>
            )}
          </>
        ) : (
          <button
            type="button"
            id="rzp-button1"
            className="btn btn-success text-dark"
            onClick={handleSubmit}
          >
            Pay 2500
          </button>
        )} */}
      </div>
    </div>
  );
}

export default Pay;


