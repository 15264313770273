
import { HeaderWrap } from './style'
import LeftSection from './LeftSection'
import RightSection from './RightSection'
import React, { Fragment, useState } from 'react';
import { AppBar, IconButton, Toolbar, Typography } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import dashboardStyle from 'components/Sidebar/Admin/dashboardstyle';


const AppHeader = ({ mobileOpen, setMobileOpen }) => {
  return (
    <Fragment >
      <HeaderWrap>
        <LeftSection mobileOpen={mobileOpen} setMobileOpen={setMobileOpen}/>
        <RightSection />
      </HeaderWrap> 
          </Fragment>
  )
}

export default AppHeader








// import React from 'react';
// import PropTypes from 'prop-types';
// import { Card, CardBody, CardTitle } from 'reactstrap';

// function CardComponent({ card }) {
//   const { title, value, iconClass, description } = card;

//   return (
//     <Card className="card-stats">
//       <CardBody>
//         <div className="col">
//           <CardTitle tag="h5" className="text-uppercase text-dark mb-0">
//             {title}
//           </CardTitle>
//           <span className="h2 font-weight-bold mb-0">{value}</span>
//         </div>
//         <div className="col-auto">
//           <div className={`icon icon-shape bg-gradient-primary text-white rounded-circle shadow ${iconClass}`}>
//             {/* Render the appropriate icon based on the iconClass */}
//             {iconClass === 'bg-gradient-primary' ? (
//               <i className="fas fa-chevron-right"></i>
//             ) : (
//               <i className="fas fa-chevron-left"></i>
//             )}
//           </div>
//         </div>
//         <p className="mt-3 mb-0 text-sm">
//           <span className="text-success mr-2">
//             <i className="fa fa-arrow-up" />
//           </span>{' '}
//           <span className="text-nowrap">{description}</span>
//         </p>
//       </CardBody>
//     </Card>
//   );
// }

// CardComponent.propTypes = {
//   card: PropTypes.shape({
//     title: PropTypes.string.isRequired,
//     value: PropTypes.string.isRequired,
//     iconClass: PropTypes.string.isRequired,
//     description: PropTypes.string.isRequired,
//   }).isRequired,
// };

// export default CardComponent;
