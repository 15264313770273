import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { viewIncome } from 'reduxStore/slices/PaymentReceivedHistory/PaymentReceivedSlice';
import { getItem } from 'utils/localStorageController';
import './Paymentreceived.css';

export default function PaymentReceived() {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user);
  const userid = user.signIn.userid;
  const paymentReceived = useSelector((state) => state.paymentReceived)
  const { data } = paymentReceived
  const a_token = getItem('a_token');
  useEffect(() => {
    dispatch(viewIncome(userid, a_token));
  }, [])

  return (
    <div className="container mt-4 ">
      <h1 className="font-weight-bold text-center">Payment Received History</h1>
      <div className="table-responsive mt-4">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className='text-lg text-center' >S.No</th>
              <th className='text-lg text-center'>Date</th>
               <th className='text-lg text-center'>Received Amount</th>
            </tr>
          </thead>
          <tbody>
            {data && data.map((item, index) => (
              <tr key={index + 1}>
                <td className='text-center'> 
                  <h3 className='text-lg'>{index + 1}</h3>
                </td>
                <td className='text-center'>
                  <h3 className='text-lg'>{item.date}</h3>
                </td>
                <td className='text-center'>
                  <h3 className='text-lg'>{item.withdraw_amount}</h3>
                </td>
              </tr>))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
