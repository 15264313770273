import React, { useState ,useEffect} from 'react'
import { getItem } from 'utils/localStorageController';
import axios from 'axios';
import { API_URL } from 'config';
const ViewAll = (pathname) => {
    const [data,setData]=useState('');
    const a_token=getItem('a_token');
    const userid=pathname.location.state.item.userid;

    const viewDetails = async () => {
    try {
      const response = await axios.get(API_URL + 'pages/user_details.php', {
        params: {
          userid: userid,
        },
        headers: {
          Authorization: `Bearer ${a_token}`,
        },
      });
      setData(response.data);
    } catch (error) {
      console.error('Error retrieving pin request:', error);
    }
  };
   useEffect(() => {
    viewDetails();
  }, []);

  return (
 <div className="container mt-4 ">
      <h1 className="font-weight-bold text-center">User Details</h1>
      <div className="table-responsive mt-4 ">
        <table className="table table-bordered">

          <thead>
            <tr>
              <th scope="col" className='text-lg' style={{textAlign:'center'}}>S.N</th>
              <th scope="col" className='text-lg' colSpan="2" style={{textAlign:'center'}}>Details</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(data).map(([key, value], index) => (
              <tr key={index + 1}>
                <td className='text-lg' style={{textAlign:'center'}}>{index + 1}</td>
                <td className='text-lg' style={{textAlign:'center'}}>{key}</td>
                <td className='text-lg' style={{textAlign:'center'}}>{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

  )
}

export default ViewAll
