/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap'

function AdminFooter() {
  return (
    <>
      <Container fluid>
        <footer className="footer pt-0" >
          <Row className="align-items-center justify-content-lg-between">
            <Col lg="6">
            </Col>
            <Col lg="6">
              <Nav className="nav-footer justify-content-center justify-content-lg-end"></Nav>
            </Col>
          </Row>
        </footer>
      </Container>
    </>
  )
}

export default AdminFooter
