import React from 'react'
import { Redirect } from 'react-router-dom';
const E = () => {
  function handleChange(){
    console.log('hi')
    return <Redirect to="/razorpay" />;
  }
  return (
    <div>
      <button onClick={handleChange}>hi</button>
    </div>
  )
}

export default E
