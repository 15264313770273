
import dashboardStyle from 'components/Sidebar/Admin/dashboardstyle';
import React, { useState } from 'react'
import Main from 'components/Sidebar/Main';
import AppHeader from './AppHeader';
function DefaultLayout() {
  const classes = dashboardStyle();
  const [mobileOpen, setMobileOpen] = useState(false);
  return (
    <>
      <AppHeader  mobileOpen={mobileOpen} setMobileOpen={setMobileOpen}/>
      <Main  mobileOpen={mobileOpen} setMobileOpen={setMobileOpen}/>
    </>
  )
}
export default DefaultLayout;
