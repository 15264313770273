
import React, { useState } from 'react';
import Logo from '././RazorPay/razorpay.webp';
import AppHeader from './AppHeader';
import Pay from './RazorPay/Pay';

const Razorpay = () => {
  return (
    <>
      <AppHeader />
      <div
        className='text-center'
        style={{
          backgroundImage: `url(${Logo})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
          width: '100vw',
          height: '100vh',
        }}
      >
        <Pay/>
      </div>
    </>
  );
};

export default Razorpay;

