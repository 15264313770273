import React from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useState } from 'react'
import ShowSpinner from 'components/Spinner/spinner'
import './Login.css'

import { loginInputChange, loginUser } from 'reduxStore/slices/user/UserSlice'
import { FaEye } from "react-icons/fa";


function Login(props) {
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const { signIn } = props
  const { userid, password } = signIn
  const session = useSelector((state) => state.user)
  const [visible, setVisible] = useState(true)
  const dispatch = useDispatch()
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleInputChange = (e) => {
    e.preventDefault()
    dispatch(loginInputChange({ name: e.target.name, value: e.target.value }))
  }

  const login = (e) => {
    e.preventDefault()
    dispatch(loginUser(userid, password))
  }

  if (session.signIn.load || session.signIn.pageLoad) {
    return <ShowSpinner />
  }
  return (
    <div className="body">
      <div className="boxlogin">
        <div className="containerlogin">
          <div className="heading-container text-center ">
            <h2 className="logo-text">Welcome to</h2>
            <h4 className="sub-heading-text">Amazing Products</h4>
          </div>
          <div className="top">
            <span className="spanlogin">Have an account?</span>
            <header className="header">Login</header>
          </div>
          <div className="input-fieldlogin">
            <input type="text" className="inputlog" placeholder="User Id"
              value={userid} name='userid'
              onChange={(e) => {handleInputChange(e)}} />
            <i className='bx bx-user icons'></i>
          </div>
          <div className="input-fieldlogin">
            <div className="password-input">
              <FaEye className={`eye-icon ${passwordVisible ? "visible" : ""}`} onClick={togglePasswordVisibility} />
              <input
                type={passwordVisible ? "text" : "password"}
                className="inputlog" placeholder="Password"
                value={password} name="password"
                onChange={(e) => {handleInputChange(e)}}
              />
              <div />
              <i className='bx bx-lock-alt icons'></i>
            </div>
            <div className="input-fieldlogin login">
              <input type="submit" className="submitlogin" value="Login" id="" onClick={login} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
