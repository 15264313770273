import React, { Fragment } from 'react'
import { LeftSectionBox } from '../style'
import BurgerButton from './BurgerButton'
import AppLogo from './AppLogo'
import AppTitle from './AppTitle'
import DashboardAppbar from './DashboardAppbar'
import RightSection from '../RightSection'

const LeftSection = ({mobileOpen,setMobileOpen}) => {
  return (
    <LeftSectionBox>
      <DashboardAppbar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen}/>
      {/* <BurgerButton />   */}
      
      {/* <AppLogo /> */}
      <AppTitle />
    </LeftSectionBox>
  )
}

export default LeftSection
