import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "config.axios";

const initialState = {
  amount : 0,
  data: null,
}

const PinRequestSlice  = createSlice({
  name:"PinRequestSlice",
  initialState,
  reducers : {
    setAmount : (state, action) => {
      state.amount = action.payload
    },
    setData : (state, action) => {
      state.data = action.payload
    }
  }
})
    
export const handlePin = ( email, amount) => async (dispatch) => {
  try {
    const response = await DataService.get("pages/pin_request.php",
      {
        params: {
          email: email,
          amount: amount,
        },
      }
    );
    dispatch(setAmount(0));
  } catch (error) {
    console.error('Error submitting pin request:', error);
  }
};

export const fetchData = (email) =>async(dispatch) => {
  try {
    const response = await DataService.get(
      'pages/pin_request_details.php',
      {
        params: {
          email: email,
        },
      }
    );
    dispatch(setData(response.data));
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};



export const { setAmount, setData} = PinRequestSlice.actions

export default PinRequestSlice.reducer
