import React from 'react'

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import DashboardRoute from 'views/Elements/DashboardRoute';
import Tree from 'views/Elements/Tree';
import PaymentReceived from 'views/Elements/PaymentReceived';
import SideBar from './SideBar';
import './Sidebar1.css';
import Withdrawalstatus from 'views/Elements/Withdrawalstatus';
import MyProfile from 'views/Elements/MyProfile';
import Join from 'views/Elements/Join';
const Main = ({ mobileOpen, setMobileOpen }) => {
    return (
        <BrowserRouter>
            <SideBar  mobileOpen={mobileOpen} setMobileOpen={setMobileOpen}>
                <Switch>
                    <Route path="/" exact component={DashboardRoute} />
                    <Route path="/dashboard" exact component={DashboardRoute} />
                    <Route path="/profile" exact component={MyProfile} />
                    <Route path="/joinUser" exact component={Join} />
                    <Route path="/tree" exact component={Tree} />
                    <Route path="/paymentReceivedHistory" exact component={PaymentReceived} />
                    <Route path="/withdrawal-status" exact component={Withdrawalstatus} />
                    <Redirect to="/"/>
                </Switch>
            </SideBar>
        </BrowserRouter>
    )
}

export default Main
