import styled from 'styled-components'

export const AppBody = styled.div`
  display: flex;
  margin-top:15px;
  position: fixed;
  background-color:blue
  right: 130px;
  bottom:20px
`
