
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from '../../../config'
import { DataService } from "config.axios";
import AppHelp from "layouts/AppHeader/RightSection/AppHelp";
                   
const initialState = {
  income: "",
};

const UserDashboardSlice = createSlice({
  name: "UserDashboardSlice",
  initialState,
  reducers: {
    setIncome: (state, action) => {
      state.income = action.payload;
    },
  },                                    
});

export const fetchData = (userid, a_token) => async (dispatch) => {
  try {
    const response = await axios.get(API_URL+
      "pages/dashboard.php",
      {
        params: {
          userid: userid,
        },
        headers: {
          Authorization: `Bearer ${a_token}`,
        },
      }
    );
    dispatch(setIncome(response.data));
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const { setIncome } = UserDashboardSlice.actions;

export default UserDashboardSlice.reducer;
