import { createSlice } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import { DataService } from 'config.axios'
import { setItem, getItem } from 'utils/localStorageController'
import { getInstanceList, updateInstanceID } from '../instance'
import { getStudyList, setStudyID, setModalState } from '../study/index'
import { activateAlert } from 'reduxStore/slices/alert/AlertSlice'
import { getWhiteLabelData } from 'reduxStore/slices/accounts/index'
import { UIPermission } from 'utils/uiPermission'
import { global_permissions } from 'variables/UserGlobalPermission'
const initialState = {
  signIn: {
    userName: '',
    userid:'',
    password: '',
    id: null,
    status: 0,
    userstatus: '',
    role: '',
    errorMsg: '',
    load: false,
    CDHError: false,
    pageLoad: false,
    showDefaultLayout: false,
    instance_permissions: {
      api_fetched: false,
      permissions: [],
    },

    global_permissions: global_permissions,
    study_permission: {},
    active_permissions: [],
    user_ui_permission: [],
  },
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginInputChange: (state, action) => {
      const { name, value } = action.payload
      state.signIn[name] = value
    },
    updateUserSession: (state, action) => {
      state.signIn.role = action.payload.role
      state.signIn.id = action.payload.id
      state.signIn.status = action.payload.status
    },
    userLoginRequest: (state, action) => {
      state.signIn.load = true
      state.signIn.pageLoad = true
    },
    updateRefreshData: (state, action) => {
      state.signIn.status = action.payload.status;
      state.signIn.role=action.payload.role;
      state.signIn.showDefaultLayout = action.payload.showDefaultLayout
      state.signIn.userstatus = action.payload.userstatus
      state.signIn.load = false;
      state.signIn.id=action.payload.id
       state.signIn.userid=action.payload.userid


    },
    userLoginSuccess: (state, action) => {
      console.log(action.payload, 'pay')
      const { role } = action.payload
      state.signIn.load = false
      state.signIn.status = action.payload.status
      state.signIn.showDefaultLayout = action.payload.showDefaultLayout
      state.signIn.role = role
      state.signIn.userstatus = action.payload.userstatus
       if(action.payload.userid){
      state.signIn.userid=action.payload.userid}
    },
    userLoginFail: (state, action) => {
      state.signIn.errorMsg = action.payload.error_msg
      state.signIn.status = action.payload.status
      state.signIn.load = false
      state.signIn.pageLoad = false
    },
    userLogout: (state, action) => {
      state.signIn.errorMsg = action.payload.error_msg
    },
    userCDHError: (state, action) => {
      state.signIn.CDHError = action.payload
    },
    fetchPermissions: (state, action) => {
      state.signIn.id = action.payload.id
      state.signIn.userName = action.payload.name
      state.signIn.instance_permissions.permissions =
      action.payload.instance_permissions
      state.signIn.instance_permissions.api_fetched = true
      state.signIn.active_permissions = action.payload.active_permissions
      state.signIn.study_permission = action.payload.study_permissions
      state.signIn.role = action.payload.role
    },
    pageLoadSuccess: (state, action) => {
      state.signIn.user_ui_permission = action.payload.user_ui_permission
      state.signIn.pageLoad = action.payload.pageLoad
      state.signIn.showDefaultLayout = action.payload.showDefaultLayout
    },
    pageLoadError: (state) => {
      state.signIn.pageLoad = false
    },
  },
})
console.log(initialState.signIn, 'initial')

export const {
  updateUserSession,
  loginInputChange,
  userLoginRequest,
  userLoginSuccess,
  userLoginFail,
  userLogout,
  userCDHError,
  fetchPermissions,
  updateRefreshData,
  pageLoadSuccess,
} = userSlice.actions

export const loginUser = (userid, password) => async (dispatch) => {
  try {
    dispatch(userLoginRequest())
    try {
      const response = await DataService.post('pages/login.php',{
        userid:userid,
        password:password
      })

      if (
        response.data.role === 'user' &&
        response.data.userstatus === 'unlock'
      ) {
        setItem('a_token', response.data.token)

        dispatch(
          userLoginSuccess({
            status: 1,
            showDefaultLayout: true,
            role: response.data.role,
            userstatus: response.data.userstatus,
          })
        )
      } else if (
        response.data.role === 'user' &&
        response.data.userstatus === 'lock'
      ) {
        setItem('a_token', response.data.token)

        dispatch(
          userLoginSuccess({
            status: 1,
            showDefaultLayout: true,
            role: response.data.role,
            userstatus: response.data.userstatus,
          })
        )
      } else {
        setItem('a_token', response.data.token)

        dispatch(
          userLoginSuccess({
            status: 1,
            role: response.data.role,
          })
        )
      }
    } catch (error) {
      console.error(error)
    }

  } catch (error) {
    
    console.error('User login', error)
  
    dispatch(
      activateAlert({
        content: error,
        color: 'danger',
      })
    )
    dispatch(userLoginFail({ error_msg: error, status: -1 }))
  }
}
export const refresh = () => async (dispatch) => {
  try {
    const response = await DataService.post('pages/verify_token.php')
console.log("response",response)
    if (
      response.data.role === 'user' &&
      response.data.userstatus === 'unlock'
    ) {

      dispatch(
        userLoginSuccess({
          status: 1,
          showDefaultLayout: true,
          role: response.data.role,
          userstatus: response.data.userstatus,
          userid:response.data.userid
        })
      )
    } else if (
      response.data.role === 'user' &&
      response.data.userstatus === 'lock'
    ) {

      dispatch(
        userLoginSuccess({
          status: 1,
          showDefaultLayout: true,
          role: response.data.role,
          userstatus: response.data.userstatus,
          userid:response.data.userid
        })
      )
    } else {

      dispatch(
        userLoginSuccess({
          status: 1,
          role: response.data.role,
          userid:response.data.userid
        })
      )
    }
  }
   catch (error) {
    console.error('Error refreshing token:', error);
  }
};

export const logoutUser = (error_msg) => {
  localStorage.clear()

  return (dispatch) => {
    dispatch(push('/'))
    window.location.reload()
  }
}

export default userSlice.reducer