import React, { Fragment } from 'react'
import NotificationIcon from 'Images/svg/NotificationIcon'

const AppNotification = () => {
  return (
    <div>
      <NotificationIcon />
    </div>
  )
}

export default AppNotification
