
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getItem } from 'utils/localStorageController';
import { useSelector } from 'react-redux';
import { API_URL } from 'config';

export default function MyProfile() {
  const [data, setData] = useState('');
  const a_token = getItem('a_token');
  const user = useSelector((state) => state.user);
  const userid = user.signIn.userid;

  const viewDetails = async () => {
    try {
      const response = await axios.get(API_URL + 'pages/user_details.php', {
        params: {
          userid: userid,
        },
        headers: {
          Authorization: `Bearer ${a_token}`,
        },
      });
      setData(response.data);
    } catch (error) {
      console.error('Error retrieving pin request:', error);
    }
  };

  useEffect(() => {
    viewDetails();
  }, []);

  return (

    <div className="container mt-4 ">
      <h1 className="font-weight-bold text-center">Profile</h1>
      <div className="table-responsive mt-4 ">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col" className='text-lg' style={{textAlign:'center'}}>S.N</th>
              <th scope="col" className='text-lg' colSpan="2" style={{textAlign:'center'}}>Details</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(data).map(([key, value], index) => (
              <tr key={index + 1}>
                <td className='text-lg' style={{textAlign:'center'}}>{index + 1}</td>
                <td className='text-lg' style={{textAlign:'center'}}>{key}</td>
                <td className='text-lg' style={{textAlign:'center'}}>{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

  );
}
