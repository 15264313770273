import { API_URL } from 'config';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getItem } from 'utils/localStorageController';

const WithdrawalRequest = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const a_token = getItem('a_token');
  const withdraw_request = async () => {
    try {
      const response = await axios.get(API_URL + 'admin/userwithdrawrequest.php', {
        headers: {
          Authorization: `Bearer ${a_token}`,
        },
      });

      setData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error retrieving pin request:', error);
    }
  };

  async function acceptHandling(id, userId,request_amount) {
    console.log(request_amount,"hbjhj")
    try {
      const sendData = await axios.post(API_URL+ 'admin/income_send.php', {
          id,
          userid: userId,
          withdraw_amount:request_amount,
      },
      {   headers: {
        Authorization: `Bearer ${a_token}`,
      }})

      if (sendData.data.message === 'Payment has been processed successfully') {
        alert('Amount Sent Successfully');
        setData(prevData => prevData.filter(item => item.id !== id));
      }
    } catch (error) {
      console.error('Error sending payment:', error);
    }
  }

  useEffect(() => {
    withdraw_request();
  }, []);

  return (
    <div className="container mt-4">
      <h1 className="font-weight-bold text-center">Withdrawal Request</h1>
      <div className="table-responsive mt-4">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col" className="text-lg" style={{textAlign:'center'}}>
                S.N
              </th>
              <th scope="col" className="text-lg" style={{textAlign:'center'}}>
                Id
              </th>
              <th scope="col" className="text-lg" style={{textAlign:'center'}}>
                User Id
              </th>
              <th scope="col" className="text-lg" style={{textAlign:'center'}}>
                Amount
              </th>
              <th scope="col" className="text-lg" style={{textAlign:'center'}}>
                Accept
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(data) && data.map((item, index) => (
              <tr key={index}>
                <th scope="row" className="text-lg">{index + 1}</th>
                <td className="text-lg" style={{textAlign:'center'}}>{item.id}</td>
                <td className="text-lg" style={{textAlign:'center'}}>{item.user_id}</td>
                <td className="text-lg" style={{textAlign:'center'}}>{item.request_amount}</td>
                <td style={{textAlign:'center'}}>
                  <button type="button" className="btn btn-success"
                    onClick={() => acceptHandling(item.id, item.user_id,item.request_amount,item.withdraw_amount,item.tdsCharge)}>
                    Accept
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default WithdrawalRequest;
