

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from 'config';
import { getItem } from 'utils/localStorageController';

export default function IncomeHistory() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage] = useState(8); // Number of items per page
  const a_token = getItem('a_token');

  const viewIncomeHistory = async () => {
    try {
      const response = await axios.get(API_URL + 'admin/income_history.php', {
        headers: {
          Authorization: `Bearer ${a_token}`,
        },
      });

      setData(response.data.data);
    } catch (error) {
      console.error('Error retrieving pin request:', error);
    }
  };

  useEffect(() => {
    viewIncomeHistory();
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const offset = currentPage * perPage;
  const currentPageData = data.slice(offset, offset + perPage);

  const generatePageNumbers = () => {
    const pageCount = Math.ceil(data.length / perPage);
    return Array.from({ length: pageCount }, (_, index) => index);
  };

  return (
    <div className="container mt-4">
      <h1 className="font-weight-bold text-center">Income History</h1>
      <div className="table-responsive mt-4">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">
                <h3>
                  <strong style={{ textAlign: 'center' }}>S.n</strong>
                </h3>
              </th>
              <th scope="col">
                <h3 style={{ textAlign: 'center' }}>
                  <strong >UserId</strong>
                </h3>
              </th>
              <th scope="col" style={{ textAlign: 'center' }}>
                <h3 style={{ textAlign: 'center' }}>
                  <strong>Date</strong>
                </h3>
              </th>
              {/* <th scope="col">
                <h3 style={{ textAlign: 'center' }}>
                  <strong>Requested Amount</strong>
                </h3>
              </th>
              <th scope="col">
                <h3 style={{ textAlign: 'center' }}>
                  <strong >TDS Charge</strong>
                </h3>
              </th> */}
              <th scope="col">
                <h3 style={{ textAlign: 'center' }}>
                  <strong >Withdraw Amount</strong>
                </h3>
              </th>
            </tr>
          </thead>
          <tbody>
            {currentPageData.map((item, index) => (
              <tr key={index + 1}>
                <td>
                  <h3 style={{ textAlign: 'center' }}>{index + 1}</h3>
                </td>
                <td>
                  <h3 style={{ textAlign: 'center' }}>{item.userid}</h3>
                </td>
                <td>
                  <h3 style={{ textAlign: 'center' }}>{item.date}</h3>
                </td>
                {/* <td>
                  <h3 style={{ textAlign: 'center' }}>{item.request_amount}</h3>
                </td>
                <td style={{ textAlign: 'center' }}>
                  <h3 style={{ textAlign: 'center' }}>{item.tds_charge}</h3>
                </td> */}
                <td>
                  <h3 style={{ textAlign: 'center' }}>{item.withdraw_amount}</h3>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <div className="pagination-list d-flex justify-content-center rounded-pill">
          {generatePageNumbers().map((pageNumber) => (
            <li
              key={pageNumber}
              className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}
            >
              <button
                className="page-link rounded-circle"
                onClick={() => handlePageChange(pageNumber)}
              >
                {pageNumber + 1}
              </button>
            </li>
          ))}
        </div>
      </div>
    </div>
  );
}
