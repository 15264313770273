import React, { useState } from 'react'
import { Grid } from "@material-ui/core";
import AppHeader from 'layouts/AppHeader';
import { AppBody } from 'style'
import SidebarAdmin from 'components/Sidebar/Admin/SidebarAdmin';
import Sidebar from 'components/Sidebar/Admin/Sidebar';
import dashboardStyle from "./dashboardstyle";
export default function Admin(props) {
  const classes = dashboardStyle();
  const [mobileOpen, setMobileOpen] = useState(false);
  return (
<div>

<AppHeader mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
  <SidebarAdmin mobileOpen={mobileOpen} setMobileOpen={setMobileOpen}/>
 

</div>

  )
}



         


