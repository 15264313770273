class UserNode {
    constructor(user,position) {
      this.name = user.name;
      this.position = position;
      this.details = {
        user_id: user.id,
        tot_left_count: 0,
        tot_right_count: 0,
        tot_left_points: 0,
        tot_right_points: 0,
        today_earnings: 0,
        tot_withdraw: 0,
        wallet_balance: 0,
        joined_date: user.joined_date,
        joining_amt: 2500,
        total_child_count: 0
      };
      this.children = [null, null];
    }
    addChild(childNode, position) {
      if(position === "left"){
        this.children[0] = childNode;
      }else if(position === "right"){
        this.children[1] = childNode;
      }
    }
  
    updateNodeCounts() {
      this.details.tot_left_count = this.countLeftNodes();
      this.details.tot_right_count = this.countRightNodes();
  
      for (const child of this.children) {
        if (child !== null) {
          child.updateNodeCounts();
        }
      }
    }
  
    countLeftNodes() {
      const leftCount = this.countNodes(this.children[0]);
  
      return leftCount;
    }
  
    countRightNodes() {
      const rightCount = this.countNodes(this.children[1]);
  
      return rightCount;
    }
  
    countNodes(node) {
      if (node === null) {
        return 0;
      }
  
      let count = 1; // Count the current node
  
      for (const child of node.children) {
        count += this.countNodes(child); // Recursively count nodes for each child
      }
  
      return count;
    }
    
    toJSON() {
      return {
          name: this.name,
          position:this.position,
          details:this.details,
          children: this.children.map((child) => (child ? child.toJSON() : null)),
      };
    }
  }
    
    class BinaryTree {
      constructor() {
        this.root = null;
        this.nodes = {};
      }
    
      addUser(user, parentUserId = null, position = null) {
        if (parentUserId === null) {
          this.root = new UserNode(user, null);
          this.nodes[user.id] = this.root; // To store references to all nodes for easy access
        } else {
          const newNode = new UserNode(user, position);
          this.nodes[user.id] = newNode; // Store the reference to the node
          // Find the parent node and add the new node as its child
          const parentNode = this.nodes[parentUserId];
          if (parentNode) {
            parentNode.addChild(newNode, position);
            this.updateNodeCounts()
          } else {
            console.error(`Parent node with ID ${parentUserId} not found.`);
          }
        }
      }
  
      updateNodeCounts() {
        if (this.root) {
          this.root.updateNodeCounts();
        }
      }
    
      getUserNode(userId) {
        return this.nodes[userId];
      }
  
      countLeftNodes(userId) {
        const node = this.nodes[userId];
        if (node) {
          return node.countLeftNodes();
        }
        return 0;
      }
  
      countRightNodes(userId) {
        const node = this.nodes[userId];
        if (node) {
          return node.countRightNodes();
        }
        return 0;
      }
    
      toJSON() {
        if (this.root) {
              return this.root.toJSON();
        } else {
          return null;
        }
      }
    }
  
    export default BinaryTree;