
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from 'reduxStore/slices/Dashboard/UserDashboardSlice';
import { getItem } from 'utils/localStorageController';
import React, { useEffect } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from 'reactstrap'
function CardsHeader({ name, parentName }) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user);
  const UserDashboardState = useSelector((state) => state.userDashboard)
  const { income } = UserDashboardState
  const userid = user.signIn.userid;
  const a_token = getItem('a_token');
  useEffect(() => {
    dispatch(fetchData(userid, a_token));
  }, []);
  const cardData = [
    {
      title: 'Today Earnings',
      value: income.total_income,
      iconClassName: 'icon icon-shape bg-gradient-gray text-white rounded-circle shadow',
      description: 'today income',
      icon: 'ni ni-active-40',
      cardColor:  'radial-gradient(circle, #FF512F, #DD2476)',

    },
    {
      title: 'Total Earnings',
      value: income.total_income,
      iconClassName: 'icon icon-shape bg-gradient-orange text-white rounded-circle shadow',
      description: 'total income',
      icon: 'ni ni-money-coins',
      cardColor:  'radial-gradient(circle, #42e695, #e08c0b)',
    },
    {
      title: ' Wallet Balance',
      value: income.current_income,
      iconClassName: 'icon icon-shape bg-gradient-orange text-white rounded-circle shadow',
      description: 'current income',
      icon: 'ni ni-chart-pie-35',
      cardColor:    'radial-gradient(circle, #f2709c, #ff9472)'

    },
    {
      title: 'Left Point',
      value: income.leftpoint,
      iconClassName: 'icon icon-shape bg-gradient-primary text-white rounded-circle shadow',
      description: 'left point',
      icon: 'fas fa-chevron-left',
      cardColor:    'radial-gradient(circle, #11998e, #38ef7d)',
    },
    {
      title: 'Right Point',
      value: income.rightpoint,
      iconClassName: 'icon icon-shape bg-gradient-primary text-white rounded-circle shadow',
      description: 'right point',
      icon: 'fas fa-chevron-right',
      cardColor:   'radial-gradient(circle, #36D1DC, #5B86E5)',

    },
    {
      title: 'Package',
      value: income.package,
      iconClassName: 'icon icon-shape bg-gradient-gray text-white rounded-circle shadow',
      description: 'package',
      icon: 'fa fa-folder',
      cardColor:    'radial-gradient(circle, #FF9F1C, #FF3C38)',
    },
  ];
  if (user.signIn.role !== 'admin') {
    cardData.push({
      title: 'Total Withdraw',
      value: income.totalwithdraw,
      iconClassName: 'icon icon-shape bg-gradient-purple text-white rounded-circle shadow',
      description: 'Total Withdraw',
      icon: 'fa fa-money',
      cardColor:'radial-gradient(circle, #fceabb, #790958)'


    });
  }
  return (
    <>
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">{name}</h6>{' '}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark">
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home"/>
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
            <Row>
              {cardData.map((item, index) => (
                <Col md="6" xl="4" className="text-center" key={index}>
                  <Card className="card-stats" style={{ background: item.cardColor }}>


                    <CardBody>
                      <Row>
                        <div className="col" >
                          <CardTitle tag="h5" className="text-uppercase text-dark mb-0 ">
                            {item.title}
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0 pl-5" >
                            {item.value}
                            <div className={item.iconClassName} style={{ marginLeft: '40px' }}>
                              <i className={item.icon} />
                            </div>
                          </span>
                        </div>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-white mr-2">
                          <i className="fa fa-arrow-up" />
                        </span>{' '}
                        <span className="text-nowrap mr-7 text-dark">{item.description}</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}
CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
}
export default CardsHeader