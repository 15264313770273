
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { joinUsers, setJoinUser, setSponserId, underuseridname, treeside } from 'reduxStore/slices/user/JoinSlice';
import { useSelector } from 'react-redux';
import './Join.css';
import { useHistory } from 'react-router-dom'; // Import useHistory hook
import { getItem } from 'utils/localStorageController';
import { API_URL } from 'config';
import axios from 'axios';
import { DataService } from 'config.axios';
import { Alert } from 'reactstrap';
import { error } from 'highcharts';

const Join = (pathname) => {
    const history = useHistory();
    const treedata = useSelector((state) => state.tree);
    const loginuser = useSelector((state) => state.user.signIn.userid);
    const { underuser, clickside } = treedata
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user)
    const userid = user.signIn.userid;
    const [update, setUpdate] = useState('')
    const [register, setRegister] = useState({
        userid: loginuser, email: '', mobile: '', address: '', under_userid: underuser, side: clickside, bankaccountno: '', sponserId: loginuser, name: '',
        dateOf_birth: '', maritalStatus: '', gender: '', country: '', state: '', district: '', city: '', pincode: '', bankName: '', account_HolderName: '', branch: '',
        ifscCode: '', fathersName: '', nomineeName: '', relationShip: '', pancardNo: '', aadharcardNo: '',
    })

    const [validationErrors, setValidationErrors] = useState({
        email: '', mobile: '', address: '', bankaccountno: '', under_userid: underuser, side: clickside, sponserId: '', dateOf_joining: '', name: '', dateOf_birth: '',
        maritalStatus: '', gender: '', country: '', state: '', district: '', city: '', pincode: '', bankName: '', account_HolderName: '', branch: '', ifscCode: '',
        fathersName: '', nomineeName: '', relationShip: '', pancardNo: '', aadharcardNo: ''
    });
    const a_token = getItem('a_token');


    const [data, setData] = useState('');

    if (pathname.location.pathname === '/editUser') {
        var userName = pathname.location.state.item.userid;
    }

    const viewDetails = async () => {
        try {
            const response = await axios.get(API_URL + 'pages/user_details.php', {
                params: {
                    userid: userName,
                },
                headers: {
                    Authorization: `Bearer ${a_token}`,
                },
            });
            setData(response.data);
        } catch (error) {
            console.error('Error retrieving pin request:', error);
        }
    };

    useEffect(() => {
        viewDetails();
    }, []);



    useEffect(() => {
        dispatch(setSponserId(userid))
        dispatch(underuseridname(underuser))
        dispatch(treeside(clickside))
    }, [])

    function validateForm() {
        const errors = {};


        // Validate mobile
        if (register.mobile.trim() === '') {
            errors.mobile = 'Mobile is required';
        } else if (!isValidMobile(register.mobile)) {
            errors.mobile = 'Invalid mobile number';
        }

        // Validate address
        if (register.address.trim() === '') {
            errors.address = 'Address is required';
        }


        // Validate email
        if (register.email.trim() === '') {
            // if (register.mobile && register.mobile.trim() === '') {

            errors.email = 'Email is required';
        } else if (!isValidEmail(register.email)) {
            errors.email = 'Invalid email address';
        }
        if (register.maritalStatus.trim() === '') {
            errors.maritalStatus = 'Marital Status is required';
        }
        if (register.fathersName.trim() === '') {
            errors.fathersName = 'Fathrer Name is required';
        }
        // Validate account
        if (register.bankaccountno.trim() === '') {
            errors.bankaccountno = 'Account is required';
        }
        if (register.relationShip.trim() === '') {
            errors.relationShip = 'Relation is required';
        }


        if (register.bankName.trim() === '') {
            errors.bankName = 'Bank Name is required';
        }

        if (register.nomineeName === '') {
            errors.nomineeName = 'Nominee is required';
        }

        // Validate name
        if (register.name.trim() === '') {
            errors.name = 'Name is required';
        }

        // Validate dateOf_birth
        if (register.dateOf_birth.trim() === '') {
            errors.dateOf_birth = 'Date of birth is required';
        }

        // Validate maritalStatus
        if (register.maritalStatus.trim() === '') {
            errors.maritalStatus = 'Marital status is required';
        }

        // Validate gender
        if (register.gender.trim() === '') {
            errors.gender = 'Gender is required';
        }

        // Validate country
        if (register.country.trim() === '') {
            errors.country = 'Country is required';
        }

        // Validate state
        if (register.state.trim() === '') {
            errors.state = 'State is required';
        }

        // Validate district
        if (register.district.trim() === '') {
            errors.district = 'District is required';
        }

        // Validate city
        if (register.city.trim() === '') {
            errors.city = 'City is required';
        }

        // Validate pincode
        if (register.pincode.trim() === '') {
            errors.pincode = 'Pincode is required';
        }

        // Validate bankName
        if (register.bankName.trim() === '') {
            errors.bankName = 'Bank name is required';
        }
        if (register.fathersName.trim() === '') {
            errors.fathersName = 'Fathrer Name is required';
        }
        if (register.pancardNo.trim() === '') {
            errors.pancardNo = 'Pan Number is required';
        }
        // Validate account_HolderName
        if (register.account_HolderName.trim() === '') {
            errors.account_HolderName = 'Account holder name is required';
        }

        // Validate branch
        if (register.branch.trim() === '') {
            errors.branch = 'Branch is required';
        }

        // Validate ifscCode
        if (register.ifscCode.trim() === '') {
            errors.ifscCode = 'IFSC code is required';
        }
        if (register.aadharcardNo.trim() === '') {
            errors.aadharcardNo = 'Aadhar is required';
        }
        setValidationErrors(errors);

        return Object.keys(errors).length === 0;
    }

    function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    function isValidMobile(mobile) {
        // Add your mobile validation logic here
        return true;
    }

    function isValidSponserId(sponserId) {
        // Add your sponserId validation logic here
        return true;
    }

    function isValidDOJ(date_of_join) {
        // Add your date_of_joining validation logic here
        return true;
    }
    function isValidFaterName(fathersName) {
        return true
    }
    function isValidAadharCardNo(aadharcardNo) {
        return true
    }
    function isValidPanCardNo(pancardNo) {
        return true
    }
    function isValidDistrict(district) {
        return true
    }
    function isValidNomineeName(nomineeName) {
        return true
    }
    function isValidAccountHolderName(acc_holder_name) {
        return true
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        const isValid = validateForm();

        if (isValid) {
            const response = dispatch(joinUsers(register))
            history.push('/tree'); // Redirect to the dashboard page


        } else {
            alert("please fill all the field")
        }
    }

    const handleUpdate = async (event) => {
        console.log(data, "hghg")

        event.preventDefault();
        try {
            const response = await DataService.post(API_URL + 'admin/update_userdetails.php', data,
                {
                    headers: {
                        Authorization: `Bearer ${a_token}`,
                    },
                });
            setUpdate(response.data);
            if (response.data.status === true) {
                alert('Details Updated Successfully')
            } else {
                alert(error)
            }
        } catch (error) {
            console.error('Error retrieving pin request:', error);
        }
    };



    return (

        <div>
            <head>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta http-equiv="X-UA-Compatible" content="ie=edge" />
                <title>Registration Form in HTML CSS</title>
                <link rel="stylesheet" href="style.css" />
            </head>
            <body className='bodys'>
                <section class="containerForm">
                    {pathname.location.pathname === '/joinUser' ? (
                        <h1 style={{ textAlign: 'center' }}>Registration Form</h1>) : (
                        <h1 style={{ textAlign: 'center' }}>Edit Form</h1>)}

                    <form action="#" class="form">
                        <div className='section'>
                            <h2>Sponser details</h2>

                            <div className='column'>
                                <div class="input-box">
                                    <label>Sponser Id</label>
                                    <input type="text" placeholder="Enter full name" name="sponserid" id="sponserid" value={register.sponserId} readOnly
                                    />
                                    {validationErrors.sponserId && (
                                        <div className="text-danger">{validationErrors.sponserId}</div>
                                    )}
                                </div>

                                <div class="input-box">
                                    <label>Under User Id</label>
                                    {pathname.location.pathname === '/joinUser' ? (

                                        <input type="text" placeholder="Enter email address"
                                            value={underuser} id="under_user_id" name="under_user_id"
                                            onChange={(e) => setRegister({ ...register, under_userid: underuser })}

                                        />
                                    ) :
                                        <input type="text"
                                            value={data.under_userid} id="under_user_id" name="under_user_id"
                                            onChange={(e) => setRegister({ ...register, under_userid: underuser })}

                                        />}

                                </div>
                            </div>

                            <fieldset className="column">
                                <div className="row">
                                    <legend className="col-form-label col-sm-2 pt-0"><b>Side</b></legend>
                                    <div className="col-sm-10">
                                        <div className="form-check">
                                            {pathname.location.pathname === '/joinUser' ? (
                                                <>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="side"
                                                        id="gridRadios1"
                                                        value={clickside}
                                                        checked={clickside}
                                                        onChange={(e) => setRegister({ ...register, side: clickside })}
                                                    />
                                                    <label className="form-check-label" htmlFor="gridRadios1">
                                                        {clickside}
                                                    </label></>) : (
                                                <>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="side"
                                                        id="gridRadios1"
                                                        value={data.side}
                                                        checked={data.side}
                                                        onChange={(e) => setRegister({ ...register, side: clickside })}
                                                    />
                                                    <label className="form-check-label" htmlFor="gridRadios1">
                                                        {data.side}
                                                    </label></>
                                            )}


                                        </div>

                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        {pathname.location.pathname === '/editUser' && (
                            <div className='section'>
                                <h2>User Details</h2>
                                <div className='column'>
                                    <div className="input-box">
                                        <label>User Id</label>
                                        <input
                                            type="text"
                                            name="userid"
                                            id="userid"
                                            value={pathname.location.state.item.userid}
                                            readOnly
                                        />

                                    </div>
                                    <div className="input-box">
                                        <label>Password</label>
                                        <input
                                            type="text"
                                            name="password"
                                            id="password"
                                            value={data.password}
                                            onChange={(e) => setData({ ...data, password: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className='section'>
                            <h2>Personal Details</h2>
                            <div class="input-box">
                                <label>Full Name</label>
                                {pathname.location.pathname === '/joinUser' ? (
                                    <input
                                        type="text"
                                        placeholder="Enter full name"
                                        name="name"
                                        id="name"
                                        onChange={(e) => setRegister({ ...register, name: e.target.value })}
                                        required
                                    />
                                ) : (
                                    <input
                                        type="text"
                                        placeholder="Enter full name"
                                        name="name"
                                        id='name'
                                        value={data.name}
                                        onChange={(e) => setData({ ...data, name: e.target.value })}

                                    />
                                )}
                            </div>

                            <div className='column'>
                                <div class="input-box">
                                    <label>Father's Name</label>
                                    {pathname.location.pathname === '/joinUser' ? (
                                        <>
                                            <input type="text" placeholder="Enter Father's name"
                                                name="fathername"
                                                id="fathername"
                                                onChange={(e) => setRegister({ ...register, fathersName: e.target.value })} required />
                                            {validationErrors.fathersName && (
                                                <div className="text-danger">{validationErrors.fathersName}</div>
                                            )}
                                        </>) : (

                                        <input type="text" placeholder="Enter Father's name"
                                            name="fathername"
                                            id="fathername"
                                            value={data.fathersname}
                                            onChange={(e) => setData({ ...data, fathersname: e.target.value })} />
                                    )}


                                </div>
                                <div class="input-box">
                                    <label>Birth Date</label>
                                    {pathname.location.pathname === '/joinUser' ? (
                                        <>
                                            <input type="date" placeholder="Enter birth date"
                                                onChange={(e) => setRegister({ ...register, dateOf_birth: e.target.value })} required />
                                            {validationErrors.dateOf_birth && (
                                                <div className="text-danger">{validationErrors.dateOf_birth}</div>
                                            )}
                                        </>) : (
                                        <input type="date" placeholder="Enter birth date" value={data.dateofbirth}
                                            onChange={(e) => setData({ ...data, dateofbirth: e.target.value })} required />
                                    )}

                                </div>
                            </div>

                            <div className='column'>
                                <div class="input-box">

                                    <label>Nominee Name</label>
                                    {pathname.location.pathname === '/joinUser' ? (
                                        <>
                                            <input type="text" placeholder="Enter nominee name"
                                                name="nominee"
                                                id="nominee"
                                                value={register.nomineeName}
                                                onChange={(e) => setRegister({ ...register, nomineeName: e.target.value })} required />
                                            {validationErrors.nomineeName && (
                                                <div className="text-danger">{validationErrors.nomineeName}</div>
                                            )}
                                        </>) : (
                                        <input type="text" placeholder="Enter nominee name"
                                            name="nominee"
                                            id="nominee"
                                            value={data.nomineename}
                                            onChange={(e) => setData({ ...data, nomineename: e.target.value })} required />
                                    )}
                                </div>

                                <div class="input-box">
                                    <label>Relation Ship</label>
                                    {pathname.location.pathname === '/joinUser' ? (
                                        <>
                                            <input type="text" placeholder="RelationShip"
                                                name="relationship"
                                                id="relationship"
                                                onChange={(e) => setRegister({ ...register, relationShip: e.target.value })} required />
                                            {validationErrors.relationShip && (
                                                <div className="text-danger">{validationErrors.relationShip}</div>
                                            )}
                                        </>) : (
                                        <input type="text" placeholder="RelationShip"
                                            name="relationship"
                                            id="relationship"
                                            value={data.relationship}
                                            onChange={(e) => setData({ ...data, relationship: e.target.value })} required />
                                    )}

                                </div>
                            </div>
                        </div>

                        <div class="column">
                            <div class="input-box">
                                <label>Phone Number</label>
                                {pathname.location.pathname === '/joinUser' ? (
                                    <>
                                        <input type="number" placeholder="Enter phone number"
                                            name="mobile"
                                            id="mobile"
                                            value={register.mobile}
                                            onChange={(e) => setRegister({ ...register, mobile: e.target.value })} required />
                                        {validationErrors.mobile && (
                                            <div className="text-danger">{validationErrors.mobile}</div>
                                        )}
                                    </>) : (
                                    <input type="number" placeholder="Enter phone number"
                                        name="mobile"
                                        id="mobile"
                                        value={data.mobile}
                                        onChange={(e) => setData({ ...data, mobile: e.target.value })} readOnly />
                                )}

                            </div>
                            <div class="input-box">
                                <label>Email Address</label>
                                {pathname.location.pathname === '/joinUser' ? (
                                    <>
                                        <input type="text" placeholder="Enter email address"
                                            name="email"
                                            id="email"
                                            onChange={(e) => setRegister({ ...register, email: e.target.value })} required />
                                        {validationErrors.email && (
                                            <div className="text-danger">{validationErrors.email}</div>
                                        )}
                                    </>) : (
                                    <input type="text" placeholder="Enter email address"
                                        name="email"
                                        id="email"
                                        value={data.email}
                                        onChange={(e) => setData({ ...data, email: e.target.value })} required />
                                )}
                            </div>
                        </div>
                        <div class="gender-box">
                            <h3>Gender</h3>
                            {pathname.location.pathname === '/joinUser' ? (
                                <>
                                    <div class="gender-option">
                                        <div class="gender">
                                            <input type="radio" id="check-male" name="gender" value='male'
                                                onChange={(e) => setRegister({ ...register, gender: e.target.value })} />
                                            <label for="check-male">male</label>
                                        </div>
                                        <div class="gender">
                                            <input type="radio" id="check-female" name="gender" value='female'
                                                onChange={(e) => setRegister({ ...register, gender: e.target.value })} />
                                            <label for="check-female">Female</label>
                                        </div>
                                        <div class="gender">
                                            <input type="radio" id="check-other" name="gender" value='others'
                                                onChange={(e) => setRegister({ ...register, gender: e.target.value })} />
                                            <label for="check-other">Others</label>
                                        </div>
                                        {validationErrors.gender && (
                                            <div className="text-danger">{validationErrors.gender}</div>
                                        )}
                                    </div>
                                    {validationErrors.gender && (
                                        <div className="text-danger">{validationErrors.gender}</div>
                                    )}
                                </>) : (
                                <div class="gender-option">
                                    <div class="gender" >
                                        <input type="radio" id="check-male" name="gender" value='male' checked={data.gender === 'male'}
                                            onChange={(e) => setData({ ...data, gender: e.target.value })} />
                                        <label for="check-male">male</label>
                                    </div>
                                    <div class="gender">
                                        <input type="radio" id="check-female" name="gender" value='female' checked={data.gender === 'female'}
                                            onChange={(e) => setData({ ...data, gender: e.target.value })} />
                                        <label for="check-female">Female</label>
                                    </div>
                                    <div class="gender">
                                        <input type="radio" id="check-other" name="gender" value='others' checked={data.gender === 'others'}
                                            onChange={(e) => setData({ ...data, gender: e.target.value })} />
                                        <label for="check-other">Others</label>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div class="gender-box">
                            <h3>Marital Status</h3>
                            {pathname.location.pathname === '/joinUser' ? (
                                <div class="gender-option">
                                    <div class="gender">
                                        <input type="radio" id="check-married" name="maritalStatus" value='married'
                                            onChange={(e) => setRegister({ ...register, maritalStatus: e.target.value })}
                                        />
                                        <label for="check-male">Married</label>
                                    </div>
                                    <div class="gender">
                                        <input type="radio" id="check-unmarried" name="maritalStatus" value='unmarried'
                                            onChange={(e) => setRegister({ ...register, maritalStatus: e.target.value })} />
                                        <label for="check-female">Unmarried</label>
                                        {validationErrors.maritalStatus && (
                                            <div className="text-danger">{validationErrors.maritalStatus}</div>
                                        )}
                                    </div>
                                </div>) : (
                                <div class="gender-option">
                                    <div class="gender">
                                        <input type="radio" id="check-married" name="maritalStatus" value='married' checked={data.maritalstatus === 'married'}
                                            onChange={(e) => setData({ ...data, maritalstatus: e.target.value })}
                                        />
                                        <label for="check-male">Married</label>
                                    </div>
                                    <div class="gender">
                                        <input type="radio" id="check-unmarried" name="maritalStatus" value='unmarried' checked={data.maritalstatus === 'unmarried'}
                                            onChange={(e) => setData({ ...setData, maritalstatus: e.target.value })} />
                                        <label for="check-female">Unmarried</label>
                                    </div>

                                </div>
                            )}
                        </div>
                        <div className='section'>
                            <h2>Proof Details</h2>
                            <div className='column'>
                                <div class="input-box">
                                    <label>AadharCard Number</label>
                                    {pathname.location.pathname === '/joinUser' ? (
                                        <>
                                            <input type="text" placeholder="Enter Aadharcard Number" required
                                                onChange={(e) => setRegister({ ...register, aadharcardNo: e.target.value })} />
                                            {validationErrors.aadharcardNo && (
                                                <div className="text-danger">{validationErrors.aadharcardNo}</div>
                                            )}
                                        </>) : (
                                        <input type="text" placeholder="Enter Aadharcard Number" required value={data.aadharcardno}
                                            onChange={(e) => setData({ ...data, aadharcardno: e.target.value })} />
                                    )}
                                </div>
                                <div class="input-box">
                                    <label>PanCard Number</label>
                                    {pathname.location.pathname === '/joinUser' ? (
                                        <>
                                            <input type="text" placeholder="Enter PanCard Number" required
                                                onChange={(e) => setRegister({ ...register, pancardNo: e.target.value })} />
                                            {validationErrors.pancardNo && (
                                                <div className="text-danger">{validationErrors.pancardNo}</div>
                                            )}
                                        </>) : (
                                        <input type="text" placeholder="Enter PanCard Number" required value={data.pancardno}
                                            onChange={(e) => setData({ ...data, pancardno: e.target.value })} />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='section'>
                            <h2>Address Details</h2>
                            <div class="input-box address">
                                <label>Address</label>
                                {pathname.location.pathname === '/joinUser' ? (
                                    <>
                                        <input type="text" placeholder="Enter street address"
                                            onChange={(e) => setRegister({ ...register, address: e.target.value })} required />
                                        {validationErrors.address && (
                                            <div className="text-danger">{validationErrors.address}</div>
                                        )}
                                    </>) : (
                                    <input type="text" placeholder="Enter street address" value={data.address}
                                        onChange={(e) => setData({ ...data, address: e.target.value })} required />
                                )}
                                <div class="column">
                                    <div class="input-box">
                                        <label>Country</label>
                                        <div class="select-box">
                                            {pathname.location.pathname === '/joinUser' ? (
                                                <>
                                                    <select
                                                        onChange={(e) => setRegister({ ...register, country: e.target.value })} >
                                                        <option hidden>Country</option>
                                                        <option>America</option>
                                                        <option>Japan</option>
                                                        <option>India</option>
                                                        <option>Nepal</option>
                                                    </select >
                                                    {validationErrors.country && (
                                                        <div className="text-danger">{validationErrors.country}</div>
                                                    )}
                                                </>) : (
                                                <select
                                                    onChange={(e) => setData({ ...data, country: e.target.value })} value={data.country}>
                                                    <option hidden>Country</option>
                                                    <option>America</option>
                                                    <option>Japan</option>
                                                    <option>India</option>
                                                    <option>Nepal</option>
                                                </select >
                                            )}

                                            <div> </div><br />
                                        </div>
                                    </div>
                                    <div class="input-box">
                                        <label>State</label>
                                        {pathname.location.pathname === '/joinUser' ? (
                                            <>
                                                <input type="text" placeholder="Enter your state" required
                                                    onChange={(e) => setRegister({ ...register, state: e.target.value })} />
                                                {validationErrors.state && (
                                                    <div className="text-danger">{validationErrors.state}</div>
                                                )}
                                            </>) : (
                                            <input type="text" placeholder="Enter your state" required value={data.state}
                                                onChange={(e) => setData({ ...data, state: e.target.value })} />
                                        )}
                                    </div> <div></div>
                                    <div class="input-box">
                                        <label>City</label>
                                        {pathname.location.pathname === '/joinUser' ? (
                                            <>
                                                <input type="text" placeholder="Enter your city"
                                                    onChange={(e) => setRegister({ ...register, city: e.target.value })}
                                                    required />
                                                {validationErrors.city && (
                                                    <div className="text-danger">{validationErrors.city}</div>
                                                )}
                                            </>) : (
                                            <input type="text" placeholder="Enter your city" value={data.city}
                                                onChange={(e) => setData({ ...data, city: e.target.value })}
                                                required />)
                                        }
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="input-box">
                                        <label>District</label>
                                        {pathname.location.pathname === '/joinUser' ? (
                                            <>
                                                <input type="text" placeholder="Enter your district" required
                                                    onChange={(e) => setRegister({ ...register, district: e.target.value })}
                                                />
                                                {validationErrors.district && (
                                                    <div className="text-danger">{validationErrors.district}</div>
                                                )}
                                            </>) : (
                                            <input type="text" placeholder="Enter your district" required value={data.district}
                                                onChange={(e) => setData({ ...data, district: e.target.value })}
                                            />
                                        )}
                                    </div>
                                    <div class="input-box">
                                        <label>Postal Code</label>
                                        {pathname.location.pathname === '/joinUser' ? (
                                            <>
                                                <input type="number" placeholder="Enter postal code" required
                                                    onChange={(e) => setRegister({ ...register, pincode: e.target.value })}
                                                />
                                                {validationErrors.pincode && (
                                                    <div className="text-danger">{validationErrors.pincode}</div>
                                                )}
                                            </>) : (
                                            <input type="number" placeholder="Enter postal code" required value={data.pincode}
                                                onChange={(e) => setData({ ...data, pincode: e.target.value })}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='section'>
                            <h2>Bank Details</h2>
                            <div className='column'>
                                <div class="input-box">
                                    <label>Bank Account No</label>
                                    {pathname.location.pathname === '/joinUser' ? (
                                        <>
                                            <input type="text" placeholder="Bank Account Number"
                                                onChange={(e) => setRegister({ ...register, bankaccountno: e.target.value })}

                                                required />
                                            {validationErrors.bankaccountno && (
                                                <div className="text-danger">{validationErrors.bankaccountno}</div>
                                            )}
                                        </>) : (
                                        <input type="text" placeholder="Bank Account Number" value={data.bankaccountno}
                                            onChange={(e) => setData({ ...data, bankaccountno: e.target.value })}

                                            required />
                                    )}

                                </div>

                                <div class="input-box">
                                    <label>Bank Name</label>
                                    {pathname.location.pathname === '/joinUser' ? (
                                        <>
                                            <input type="text" placeholder="Bank  Name" required
                                                onChange={(e) => setRegister({ ...register, bankName: e.target.value })}
                                            />
                                            {validationErrors.bankName && (
                                                <div className="text-danger">{validationErrors.bankName}</div>
                                            )}
                                        </>) : (

                                        <input type="text" placeholder="Bank  Name" required value={data.bankname}
                                            onChange={(e) => setData({ ...data, bankname: e.target.value })}
                                        />
                                    )}

                                </div>
                            </div>
                            <div className='column'>
                                <div class="input-box">
                                    <label>Account Holder Name</label>
                                    {pathname.location.pathname === '/joinUser' ? (
                                        <>
                                            <input type="text" placeholder=" Account Holder Name" required
                                                onChange={(e) => setRegister({ ...register, account_HolderName: e.target.value })}
                                            />
                                            {validationErrors.account_HolderName && (
                                                <div className="text-danger">{validationErrors.account_HolderName}</div>
                                            )}
                                        </>) : (
                                        <input type="text" placeholder=" Account Holder Name" required value={data.bankaccountholdername}
                                            onChange={(e) => setData({ ...data, bankaccountholdername: e.target.value })}
                                        />
                                    )}

                                </div>

                                <div class="input-box">
                                    <label>Branch</label>
                                    {pathname.location.pathname === '/joinUser' ? (
                                        <>
                                            <input type="text" placeholder="Enter Branch" required
                                                onChange={(e) => setRegister({ ...register, branch: e.target.value })}
                                            />
                                            {validationErrors.branch && (
                                                <div className="text-danger">{validationErrors.branch}</div>
                                            )}
                                        </>) : (
                                        <input type="text" placeholder="Enter Branch" required value={data.branch}
                                            onChange={(e) => setData({ ...data, branch: e.target.value })}
                                        />
                                    )}

                                </div>
                                <div class="input-box">
                                    <label>IFSC</label>
                                    {pathname.location.pathname === '/joinUser' ? (
                                        <>
                                            <input type="text" placeholder="Enter IFSC Code" required
                                                onChange={(e) => setRegister({ ...register, ifscCode: e.target.value })}
                                            />
                                            {validationErrors.ifscCode && (
                                                <div className="text-danger">{validationErrors.ifscCode}</div>
                                            )}
                                        </>) : (
                                        <input type="text" placeholder="Enter IFSC Code" required value={data.ifsc}
                                            onChange={(e) => setData({ ...data, ifsc: e.target.value })}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        {pathname.location.pathname === '/joinUser' ? (
                            <button onClick={handleSubmit}>Submit</button>) : (
                            <button onClick={handleUpdate} type='submit'>Update</button>
                        )}
                    </form>
                </section>
            </body>
        </div>
    )
}

export default Join
