import { AppBar, IconButton, Toolbar, Typography } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import dashboardStyle from "components/Sidebar/Admin/dashboardstyle";

const DashboardAppbar = ({ mobileOpen, setMobileOpen }) => {
  const classes = dashboardStyle();
  console.log("inside",mobileOpen)
  return (
    // <AppBar position="fixed" className={classes.AppBar}>
      <Toolbar>
        <IconButton
          onClick={() => setMobileOpen(!mobileOpen)}
          edge="start"
          className={classes.menuButton}
        >
          <Menu />
        </IconButton>
        {/* <Typography variant="h6">Dashboard</Typography> */}
      </Toolbar>
    // </AppBar>
  );
};

export default DashboardAppbar;
