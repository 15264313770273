
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CardsHeader from 'components/Headers/CardsHeader';
import AuthHeader from 'components/Headers/AuthHeader';


function AdminDashboardRoute() {

  const dispatch = useDispatch()
  const user = useSelector((state) => state.user);
  const UserDashboardState = useSelector((state) => state.userDashboard)
  const { income } = UserDashboardState
  const userid = user.signIn.userid;

  return (
    <>
    <CardsHeader name="Default" parentName="AdminDashboard" />
    <AuthHeader/>
</>
   
  );
}

export default AdminDashboardRoute;
