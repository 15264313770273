import axios from 'axios'
import { DataService } from 'config.axios'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { unlockHandling } from 'reduxStore/slices/UserInitialPayment/UserInitialPaymentSlice'
import { fetchData } from 'reduxStore/slices/UserInitialPayment/UserInitialPaymentSlice'
import './Table.css'
export default function UsersInitialPayment() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const UserInitialPayment = useSelector((state) => state.UserInitialPayment);
  const [data, setData] = useState([]);
  const { payment } = UserInitialPayment;

  const handleCheckboxChange = async (id, userid) => {
    try {
      await DataService.post('admin/manual_payment_checked.php', {
        userid: userid,
        amount: 2500,
      });
      dispatch(fetchData());
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(fetchData());
  }, []);

  return (
    <div className="container ">
      <h1 className="font-weight-bold text-center">
        User Initial Payment History
      </h1>
      <div className="table-responsive ">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">
                <h3>
                  <strong>Id</strong>
                </h3>
              </th>
              <th scope="col">
                <h3>
                  <strong>UserId</strong>
                </h3>
              </th>
              <th scope="col">
                <h3>
                  <strong>Password</strong>
                </h3>
              </th>
              <th scope="col">
                <h3>
                  <strong>Sponser Id</strong>
                </h3>
              </th>
              <th scope="col">
                <h3>
                  <strong>DateOf Joining</strong>
                </h3>
              </th>
              <th scope="col">
                <h3>
                  <strong>Mobile</strong>
                </h3>
              </th>

              <th scope="col">
                <h3>
                  <strong>Payment Mode</strong>
                </h3>
              </th>
              <th scope="col">
                <h3>
                  <strong>Unlock</strong>
                </h3>
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(payment) &&
              payment.map((item, index) => (
                <tr key={index + 1}>
                  <td>
                    <h2>{item.id}</h2>
                  </td>
                  <td>
                    <h2>{item.userid}</h2>
                  </td>
                  <td>
                    <h2>{item.password}</h2>
                  </td>
                  <td>
                    <h2>{item.sponserId}</h2>
                  </td>
                  <td>
                    <h2>{item.dateOf_joining}</h2>
                  </td>
                  <td>
                    <h2>{item.mobile}</h2>
                  </td>

                  <td>
                    {(() => {
                      if (item.payment_mode === 'manual') {
                        return (
                          <div
                            style={{
                              display: 'inline-flex',
                              alignItems: 'center',
                            }}
                          >
                            <h2 style={{ marginRight: '10px' }}>
                              {item.payment_mode}
                            </h2>
                            <input
                              type="checkbox"
                              className="custom-checkbox"
                              id="payment_paid"
                              style={{
                                width: '20px',
                                height: '20px',
                                border: '2px solid #333',
                                backgroundColor: '#fff',
                                borderRadius: '4px',
                                verticalAlign: 'middle',
                                marginRight: '5px',
                              }}
                              onChange={() =>
                                handleCheckboxChange(item.id, item.userid)
                              }
                            />
                          </div>
                        )
                      } else {
                        return <h2 style={{ textAlign: 'center' }}>{item.payment_mode}</h2>
                      }
                    })()}
                  </td>
                  <td>
                    {item.initial_payment === 'paid' ? (
                      <button
                        className="btn btn-success"
                        onClick={() =>
                          dispatch(unlockHandling(item.id, item.userid))
                        }
                      >
                        Unlock
                      </button>
                    ) : (
                      <button className="btn btn-danger">Lock</button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
