import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { getItem } from 'utils/localStorageController'
import { ConnectedRouter } from 'connected-react-router'
import { refresh } from 'reduxStore/slices/user/UserSlice'

import DefaultLayout from './layouts/DefaultLayout'
import Login from './views/Login'

// Home Page
import { Container } from 'sdq-ui';
import CodeEditor from 'components/CodeEditor';
import Razorpay from 'layouts/Razorpay';
import Ecom from 'layouts/Ecom/Ecom.js';

import Admin from 'components/Sidebar/Admin/Admin';
import E from 'layouts/Ecom/E'
import MyProfile from 'views/Elements/MyProfile'
import Profile from 'layouts/Profile'
function App({ history }) {
  const user = useSelector((state) => state.user)
  const { signIn } = user
  const a_token = getItem('a_token')
  const dispatch = useDispatch()
  useEffect(() => {
    if (signIn.status === 0 && a_token !== null) {
      dispatch(refresh())
    }
  }, [signIn.status, a_token, dispatch]);
  return (
    <ConnectedRouter history={history}>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            name="Login"
            render={() =>
              signIn.status === 1 && signIn.role === 'admin' ? (
                <Redirect to="/admin" />
              ) : signIn.status === 1 &&
                signIn.role === 'user' &&
                signIn.userstatus === 'unlock' ? (
                <Redirect to="/dashboard" />
              ) : signIn.status === 1 &&
                signIn.role === 'user' &&
                signIn.userstatus === 'lock' ? (
                <Redirect to="/razorpay" />
              ) : 
              signIn.status===1 &&signIn.role==='customer'?(<Redirect to='/ecom'/>):(
                <Container isNoPadding={true}>
                  <Login signIn={signIn} />
                </Container>
              )
            }
          />

          <Route
            path="/dashboard"
            name="Dashboard"
            render={() =>
              signIn.status === 1 &&
              signIn.role === 'user' &&
              signIn.userstatus === 'unlock' ? (
                <DefaultLayout />
              ) : (
                <Redirect to="/" />
              )
            }
          />

          <Route
            path="/razorpay"
            name="Razorpay"
            render={() =>
              signIn.status === 1 &&
              signIn.role === 'user' &&
              signIn.userstatus === 'lock' ? (
                <Razorpay />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            path="/admin"
            name="Admin"
            render={() =>
              signIn.status === 1 && signIn.role === 'admin' ? (
                <Admin />
              ) : (
                <Redirect to="/" />
              )
            }
          />
  <Route
            path="/ecom"
            name="Ecom"
            render={() =>
              signIn.status === 1 && signIn.role === 'customer' ? (
                <Ecom/>
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            path="/code-editor"
            name="CodeEditor"
            render={() => <CodeEditor />}
          />
          <Route path='/profile'
          name='profile'
          render={()=><MyProfile/>}/>
          <Route path="login" name="Login" render={() => <Login />} />
          <Route path='/myprofile'
          name='profile'
          render={()=><Profile/>}/>
          


          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </ConnectedRouter>
  )
}

export default App

