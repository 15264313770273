import React, { useState } from 'react';
import './Sidebar1.css';
import {
  FaHome,
  FaHistory,
  FaTree,
  FaMoneyBill,
  FaUser
} from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const SideBar = ({ mobileOpen, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  console.log("mobile", mobileOpen);
  const toggle = () => setIsOpen(!isOpen);
  const menuItem = [
    {
      path: '/dashboard',
      name: 'Dashboard',
      icon: <FaHome />
    },
    {
      path: '/tree',
      name: 'Tree',
      icon: <FaTree />
    },
    {
      path: '/paymentReceivedHistory',
      name: 'Payment',
      icon: <FaHistory />
    },
    {
      path: '/withdrawal-status',
      name: 'Withdrawal',
      icon: <FaMoneyBill />
    },
    {
      path: '/profile',
      name: 'My Profile',
      icon: <FaUser />
    }
  ];

  return (
    <div className='containers'>
      <div
        style={{
          width: mobileOpen ? '240px' : (mobileOpen ? '50px' : '0px'),
          display: mobileOpen || mobileOpen ? 'block' : 'none'
        }}
        className='sidebar'
      >
        {menuItem.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className='link'
            activeClassName='active'
          >
            <div className='icon-container'>
              <div className='icon' >{item.icon}</div>
              <div style={{ display: mobileOpen ? 'block' : 'none' }} className='link_text'>
                {item.name}
              </div>
            </div>
          </NavLink>
        ))}
      </div>
      <main className='main'>{children}</main>
    </div>
  );
};

export default SideBar;
