
import React, { useEffect, useState, useRef, useMemo } from 'react';
import Tree from 'react-d3-tree';
import BinaryTree from './Tree';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { getItem } from 'utils/localStorageController';
import { underuser, clickside } from 'reduxStore/slices/Tree/TreeSlice';
import { API_URL } from 'config';
import { Link } from 'react-router-dom';
import './Tree.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faUser, faUserAltSlash,  } from '@fortawesome/free-solid-svg-icons';
import { textAlign } from 'styled-system';

export default function Home() {
  const a_token = getItem('a_token');

  const dispatch = useDispatch();
  const tree = useMemo(() => new BinaryTree(), []);
  const treeContainer = useRef(0);
  const [treeData, setTreeData] = useState({});
  const [translate, setTranslate] = useState({});
  const [registration, setRegistration] = useState(false);
  const [currentParent, setCurrentParent] = useState({});
  const [clickedNode, setClickedNode] = useState('');
  const [searchId, setSearchID] = useState("");
  const user = useSelector((state) => state.user);
  const userid = user.signIn.userid;
  const role = user.signIn.role;
  const [searchValue, setSearchValue] = useState('');
  const [hoveredItem, setHoveredItem] = useState(null);
  const join = useSelector((state) => state.join);
  const Join = join.join_user
  const getDimensions = () => {
    const dimensions = treeContainer.current.getBoundingClientRect();
    setTranslate({
      x: dimensions.width /4,
      y: dimensions.height /4,
    });
  };
 
  useEffect(() => {
    getDimensions();
    updateTreeData();
  }, []);
  
  

  const replaceNullWithEmpty = (obj) => {
    if (obj.unlock_details !== "lock") {
      if (obj.children) {
        for (let i = 0; i < obj.children.length; i++) {
          const child = obj.children[i];
          if (child === null) {
            obj.children[i] = { "name": 'empty' }; // Replace null with an empty object
          } else {
            replaceNullWithEmpty(child); // Recursively check and replace null values
          }
        }
      }
    } else {
      if (obj.children) {
        for (let i = 0; i < obj.children.length; i++) {
          const child = obj.children[i];
          if (child === null) {
            obj.children[i] = { "name": 'unread' }; // Replace null with an empty object
          } else {
            replaceNullWithEmpty(child); // Recursively check and replace null values
          }
        }
      }
    }
  };
  

  const updateTreeData = async () => {
    try {
      const response = await axios.get(API_URL + 'pages/tree.php', {
        params: {
          userid: userid,
        },
        headers: {
          Authorization: `Bearer ${getItem('a_token')}`,
        },
      });
      if(response.status == 200){
        replaceNullWithEmpty(response.data);
        setTreeData(response.data);
      }
     console.log(response.data,"data")
    } catch (error) {
      console.error('Error fetching API data:', error);
    }
  };
  
  
const handleDelete = async ( userid) => {
  setHoveredItem(null)

  try {
        let isExecuted = window.confirm("Are you sure to delete this action?");
        if(isExecuted){
          const dealetId = await axios.post(
            API_URL + 'admin/delete_users_in_tree.php',
            {
              userid: userid,
            },
            {
              headers: {
                Authorization: `Bearer ${a_token}`,
              },
            }
          );
          if(dealetId.data.success===true){
            updateTreeData()
          }
  }
  } catch (error) {
    console.error('Error sending payment:', error);
  }
};

  const handleImageClick = (event, nodeDatum, parentNode) => {

    const isLeftNode =
      parentNode.parent.data.children &&
      parentNode.parent.data.children[0] === nodeDatum;
    isLeftNode ? setClickedNode('left') : setClickedNode('right');
    if (isLeftNode) {
      dispatch(clickside('left'));
    } else {
      dispatch(clickside('right'));
    }
    setRegistration(true);
    setCurrentParent(parentNode.parent.data.name);
    dispatch(underuser(parentNode.parent.data.name));
  };

 
  const handleSearch = async () => {
    try {
      const response = await axios.get(API_URL + 'pages/tree.php', {
        params: {
          userid: searchValue,
        },
        headers: {
          Authorization: `Bearer ${getItem('a_token')}`,
        },
      });

      replaceNullWithEmpty(response.data);
      setTreeData(response.data);
    } catch (error) {
      console.error('Error fetching API data:', error);
    }
  };

  const handleMouseEnter = (nodeDatum) => {
    setHoveredItem(nodeDatum);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const renderRectSvgNode = ({ nodeDatum, hierarchyPointNode }) => {
    if (nodeDatum.name !== 'empty' && nodeDatum.unlock_details === 'unlock' && role === 'admin') {
      return (
        <g
          onMouseEnter={() => handleMouseEnter(nodeDatum)}
          onMouseLeave={handleMouseLeave}
        >
          <circle r={10} fill="blue" stroke="none" className='name'
          />
          <text
            dx={40}
            textAnchor="right"
            style={{ fontSize: 15, stroke: 'none' }}
            fill="black"
            fontWeight="bold"
          >
            {nodeDatum.name}
          </text>
          <text
            dx={40}
            dy={15}
            textAnchor="right"
            style={{ fontSize: 15, stroke: 'none' }}
            fill="black"
            fontWeight="bold"
          >
            left: {nodeDatum.attributes?.left_count}
          </text>
          <text
            dx={40}
            dy={30}
            textAnchor="right"
            style={{ fontSize: 15, stroke: 'none' }}
            fill="black"
            fontWeight="bold"
          >
            right: {nodeDatum.attributes?.right_count}
          </text>
          <text
            dx={40}
            dy={45}
            textAnchor="right"
            style={{ fontSize: 15, stroke: 'none' }}
            fill="black"
            fontWeight="bold"
          >
            userstatus: {nodeDatum.unlock_details}
          </text>
          {hoveredItem === nodeDatum && (
            <foreignObject x={100} y={-150} width="250" height="280">
              <div className="card p-2 rounded" style={{ backgroundColor: 'rgb(255,174,66)', color: 'black',marginTop:'60px' ,textAlign:'center'}}>
                <p>User Id: {nodeDatum.name.slice(0, -4) + 'X'.repeat(6)}</p>
                <p>Left Count:  {nodeDatum.attributes?.left_count}</p>
                <p>Right Count:  {nodeDatum.attributes?.right_count}</p>
                <p>Left Point :{(nodeDatum.attributes?.left_count)*2.5}</p>
                <p>Right Point :{(nodeDatum.attributes?.right_count)*2.5}</p>

              </div>
            </foreignObject>
          )}
        </g>
      );
    } else if (nodeDatum.name !== 'empty' && nodeDatum.unlock_details === 'unlock' && role === 'user') {
      console.log(nodeDatum,"node")
      return (
        <g
          onMouseEnter={() => handleMouseEnter(nodeDatum)}
          onMouseLeave={handleMouseLeave}
        >
          <circle r={10} fill="blue" stroke="none" className='name' />
   
          {hoveredItem === nodeDatum && (
            <foreignObject x={30} y={-130} width="250" height="280" >
              <div className="card p-2 rounded" style={{ backgroundColor: 'rgb(255,174,66)', color: 'black' ,textAlign:'center'}}>
                <p>User Id: {nodeDatum.name.slice(0, -4) + 'X'.repeat(6)}</p>
                <p>Left Count:  {nodeDatum.attributes?.left_count}</p>
                <p>Right Count:  {nodeDatum.attributes?.right_count}</p>
                <p>Left Point :{(nodeDatum.attributes?.left_count)*2.5}</p>
                <p>Right Point :{(nodeDatum.attributes?.right_count)*2.5}</p>
              </div>
            </foreignObject>
          )}
        </g>
      );
    
  } else if (nodeDatum.name !== 'empty' && nodeDatum.unlock_details === 'lock') {
    return (
      <g >
        <circle r={10} fill="red" stroke="none" 
        onMouseEnter={() => handleMouseEnter(nodeDatum)} 
        onMouseLeave={handleMouseLeave}/>
  
        <foreignObject x={30} y={5} width="70" height="30">
          <FontAwesomeIcon
            icon={faTrash}
            type='button'
            style={{ color: 'red' }}
            onClick={() => handleDelete(nodeDatum.name)}
          />
        </foreignObject>
          {hoveredItem === nodeDatum && (
            <foreignObject x={10} y={-60} width="250" height="150" >
              <div className="card p-2 rounded" style={{ backgroundColor: 'rgb(255,174,66)', color: 'black' ,textAlign:'center'}}>
              <p>User Id: {nodeDatum.name.slice(0, -4) + 'X'.repeat(6)}</p>

              </div>
            </foreignObject>
          )}
        </g>
      );
    } else if (nodeDatum.name === 'empty') {
      return (
        <g
        >
          <Link to={{ pathname: "/joinUser" }}>
            <FontAwesomeIcon
              icon={faUser}
              x="-12"
              y="-12"
              width="25"
              height="25"
              onClick={(event) => handleImageClick(event, nodeDatum, hierarchyPointNode)}
              style={{ color: 'blue', cursor: 'pointer' }}
            />
          </Link>
        </g>
      );
    } else {
      return (
        <g>
          <FontAwesomeIcon
            icon={faUserAltSlash}
            x="-12"
            y="-12"
            width="25"
            height="25"
            onClick={(event) => handleImageClick(event, nodeDatum)}
            style={{ color: 'red' }}
          />
        </g>
      );
    }
  };

  return (
    <div className='row' style={{ backgroundColor: 'rgb(17,205,239)' }}>
      {user.signIn.role === 'admin' && (
    
    <div className="input-group pl-4 pt-2">
  <div className="form-outline">
    <input type="search" id="form1" className="form-control" placeholder='Search . . . . . . . . . .' value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}/>
  </div>
  <button type="button" className="btn btn-primary btn-sm" style={{height:'45px',marginLeft:'2px'}}>
    <i className="fas fa-search" onClick={handleSearch}></i>
  </button>
</div>
      )}
      <div className='col'>
        <div style={{ width: '100%', height: "100vh" }} ref={treeContainer}>
          {treeData && (
            <Tree
              data={treeData}
              translate={translate}
              orientation='vertical'
              renderCustomNodeElement={(nodeProps) =>
                renderRectSvgNode({ ...nodeProps, role })
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}

