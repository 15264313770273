import React, { useState } from 'react';
import { FaHome, FaTree, FaWallet, FaChartLine, FaUserCheck, FaMoneyCheckAlt, FaBars, FaTimes } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const Sidebar = ({ mobileOpen, setMobileOpen, toggleSidenav, sidenavOpen, routes, logo, rtlActive, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const menuItem = [
    {
      path: '/dashboard',
      name: 'Dashboard',
      icon: <FaHome />
    },
    {
      path:'/tree',
      name:'Tree',
      icon:<FaTree />
    },
    {
      path:'/user_initial_payment',
      name:'Initial Payment',
      icon:<FaWallet/>
    },
    {
      path: '/incomeHistory',
      name: 'Income History',
      icon: <FaChartLine />
    },
    {
      path: '/userDetails',
      name: 'User Details',
      icon: <FaUserCheck />
    },
    {
      path: '/withdrawal',
      name: 'Withdrawal Request',
      icon: <FaMoneyCheckAlt />
    }
  ];

  return (
    <div className='containers'>
      <div style={{ width: mobileOpen ? '240px' : (mobileOpen ? '50px' : '0'),
    display: mobileOpen || mobileOpen ? 'block' : 'none'
    }} className='sidebar'>
       
        {menuItem.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className='link'
            activeClassName='active'
          >
                        <div className='icon-container'>

            <div className='icon'>{item.icon}</div>
            <div style={{ display: mobileOpen ? 'block' : 'none' }} className='link_text'>
              {item.name}
            </div>
            </div>

          </NavLink>
        ))}
      </div>
      <main className='main'>{children}</main>
    </div>
  );
};

export default Sidebar;

