
import './Ecom.css'
import image1 from './image1.webp';
import image2 from './image2.jpg';
import image3 from './image3.jpg';
import image4 from './image4.jpg';
import image5 from './image5.avif';
import image6 from './image6.jpg';
import image7 from './image7.webp';
import image8 from './image8.jpg';
import image9 from './image9.webp';
import image10 from './image10.jpg';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom'; 
import axios from 'axios'
import { API_URL } from 'config'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getItem } from 'utils/localStorageController'
import AppHeader from 'layouts/AppHeader';

const Ecom = () => {
  const data = [{
    prize: 2500,
    image: image1,
    id: 1
  },
  {
    prize: 2500,
    image: image2,
    id: 2
  },
  {
    prize: 2500,
    image: image3,
    id: 3
  },
  {
    prize: 2500,
    image: image4,
    id: 4
  },
  {
    prize: 2500,
    image: image5,
    id: 5
  },
  {
    prize: 2500,
    image: image6,
    id: 6
  },
  {
    prize: 2500,
    image: image7,
    id: 7

  },
  {
    prize: 2500,
    image: image8,
    id: 8

  },
  {
    prize: 2500,
    image: image9,
    id: 9

  },
  {
    prize: 2500,
    image: image10,
    id: 10

  },

  ]
  const history = useHistory(); 

  function handleChange(){
    history.push('/razorpay');

    console.log(history,"nj");
  }
   const [id, setId] = useState('')
  const [paymentId, setPaymentId] = useState('')
  const [orderId, setOrderid] = useState('')
  const [signature, setSignature] = useState('')
  const [showCongrats, setShowCongrats] = useState(false);
  const user = useSelector((state) => state.user)
  const userid = user.signIn.userid
  const a_token = getItem('a_token')
  const [success, setSuccess] = useState('')
  const [datas, setDatas] = useState('');
  console.log(success, "rdatassssss")
  const paymentrequest = async () => {
    try {
      const response = await axios.post(API_URL + 'pages/manual_paymentrequest.php',
        {
          userid: userid
        },
        {
          headers: {
            Authorization: `Bearer ${a_token}`,
          },
        })
      if (response.data.status === true) {
        alert('AMOUNT 2500 PAY SUCCESSFULLY')
      }
    } catch (error) {
      console.error(error)
    }
  }
  const viewDetails = async () => {
    try {
      const response = await axios.get(API_URL + 'pages/user_details.php', {
        params: {
          userid: userid,
        },
        headers: {
          Authorization: `Bearer ${a_token}`,
        },
      });
      setDatas(response.data);
    } catch (error) {
      console.error('Error retrieving pin request:', error);
    }
  };
  useEffect(() => {
    viewDetails();
  }, []);
  const handleSubmit = async (e) => {
    setShowCongrats(true);

    e.preventDefault()
    var options = {
      
      key: 'rzp_test_k12uQQAZ4jbzsq',
      key_secret: 'P7833oux8WO0m4iXnI5bLaaD',

      amount: 250000,
      currency: 'INR',
      name: 'amazingproduct',
      email: 'gvivek548@gmail.com',
      order_id: id,
      prefill: {
        name: datas.name,
        email: datas.userid,
        contact: datas.mobile,
      },
      notes: {
        address: 'Razorpay Corporate office',
      },
      theme: {
        color: '#3399cc',
      },
      handler: async function (response) {
        setPaymentId(response.razorpay_payment_id)

        setOrderid(response.razorpay_order_id)

        setSignature(response.razorpay_signature)
        try {
          const data = {
            razorpay_payment_id: response.razorpay_payment_id,
            order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
            userid: userid,
          }
          const payment = await axios.post(
            API_URL + 'razorpay/verify_payment_signature.php',
            data,
            {
              headers: {
                Authorization: `Bearer ${a_token}`,
              },
            }
          )
          if (payment.data.status === true) {
            setSuccess(payment.data)
            alert('Amount paid successFully')

            return
          }
          else {
            alert('Something went wrong.....try again')
          }
        } catch (error) {
          console.error('Error verifying payment signature:', error)
        }
      },
    }
    var rzp1 = new window.Razorpay(options)
    rzp1.on('payment.failed', function (response) {
      alert(response.error.code)
      alert(response.error.description)
      alert(response.error.source)
      alert(response.error.step)
      alert(response.error.reason)
      alert(response.error.metadata.order_id)
      alert(response.error.metadata.payment_id)
    })
    rzp1.open()
  }
  useEffect(() => {
    const response = axios.post(API_URL + 'razorpay/pay.php', {
      header: {
        Accept: "application/json",
        "Content-type": "multiple/form-data",
      },
    })
      .then((response) => ((setId(response.data.id))))
      .catch((error) => {
        console.error('Error:', error);
      });
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
  }, [success])

  return (
    <div className='mine'>
      <AppHeader/>
      <h1 className='h1'>Welcome to Amazing Products</h1>
      <div className='ecom'>
        <div className='item'>
          {data.map((items) => (
            // <React.Fragment key={items.id}>
              <div className='all'>
                <div className='details'>
                  <img src={items.image} alt={items.image} />
                </div>

                <Link to={{ pathname: "/razorpay", state:{items}}} >
                <button className='btn btn-primary btn btn-success text-dark'  type="button"
            id="rzp-button1"
          
            onClick={handleSubmit}
                >
                  {items.prize}
                </button>
              </Link>
              </div>
            // </React.Fragment>
          ))}
        </div>
      </div>
   
    </div>
  )
}

export default Ecom
