import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "config.axios";
import { API_URL } from "config";
const initialState = {
    income: ""
}

const UserDashboardSlice = createSlice({
    name: "UserDashboardSlice",
    initialState,
    reducers: {
        setIncome: (state, action) => {
            state.income = action.payload
        }
    }
})

export const fetchData = () => async (dispatch) => {
    try {
      const response = await DataService.get(API_URL+
        'admin/home.php',
        
      );
      dispatch(setIncome(response.data));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
export const{ setIncome } = UserDashboardSlice.actions

export default UserDashboardSlice.reducer