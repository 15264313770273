import { createSlice } from '@reduxjs/toolkit'
import { DataService } from 'config.axios'
import { activateAlert } from 'reduxStore/slices/alert/AlertSlice'
const initialState = {
  join_user: {
    userid: '',
    sponserId: '',
  },
}
export const JoinSlice = createSlice({
  name: 'join',
  initialState,
  reducers: {
    setJoinUser: (state, action) => {
      state.join_user = action.payload
    },

    setSponserId: (state, action) => {
      state.join_user.sponserId = action.payload
      state.join_user.userid = action.payload
    },
    underuseridname: (state, action) => {
      state.join_user.under_userid = action.payload
    },
    treeside: (state, action) => {
      state.join_user.side = action.payload
    },
   
  },
})

export const joinUsers = (join) => async (dispatch) =>{
  try {
    console.log('Payload received')
    const response = await DataService.post('pages/join.php', join)

    console.log('Form data sent successfully:', response.data)
    if(response.data.success==='user added successfully.'){
      alert("user added successfully")
    }
      else{
      console.log("errror")
      alert(response.data.error)
      throw new Error(response.data.error)
    }
  } catch (error) {
    console.error('Error sending form data:', error)
    dispatch(
      activateAlert({
        content: error.message, 
        color: 'danger',
      })
    )
  }
}

export const { setJoinUser, setSponserId, underuseridname, treeside } =
  JoinSlice.actions

export default JoinSlice.reducer
