// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { API_URL } from 'config';
// import { getItem } from 'utils/localStorageController';
// import { faEdit, faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
// import { FaEdit, FaEye, FaInfoCircle, FaStreetView } from 'react-icons/fa';
// import { Link } from 'react-router-dom';

// const UserDetails = () => {
//   const [data, setData] = useState([]);
//   const [currentPage, setCurrentPage] = useState(0);
//   const [itemsPerPage] = useState(7);
//   const a_token = getItem('a_token');

//   const viewIncomeHistory = async () => {
//     try {
//       const response = await axios.get(API_URL + 'admin/userdetails.php', {
//         headers: {
//           Authorization: `Bearer ${a_token}`,
//         },
//       });

//       setData(response.data);
//     } catch (error) {
//       console.error('Error retrieving pin request:', error);
//     }
//   };

//   useEffect(() => {
//     viewIncomeHistory();
//   }, []);

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   const pageCount = Math.ceil(data.length / itemsPerPage);
//   const currentItems = data.slice(
//     (currentPage * itemsPerPage) % data.length,
//     ((currentPage * itemsPerPage) % data.length) + itemsPerPage
//   );

//   const generatePageNumbers = () => {
//     const pageNumbers = [];
//     const range = 2; 

//     let start = currentPage - range;
//     let end = currentPage + range;

//     if (start < 0) {
//       start = 0;
//       end = Math.min(range * 2, pageCount - 1);
//     }

//     if (end >= pageCount) {
//       end = pageCount - 1;
//       start = Math.max(0, pageCount - range * 2 - 1);
//     }

//     for (let i = start; i <= end; i++) {
//       pageNumbers.push(i);
//     }

//     return pageNumbers;
//   };

//   return (
//     <div className="container mt-12">
//       <h1 className="font-weight-bold text-center">User Details</h1>
//       <div className="table-responsive mt-4">
//         <table className="table table-bordered">
//           <thead>
//             <tr>
//               <th scope="col">
//                 <h3 className="font-weight-bold" style={{textAlign:'center'}}>S.n</h3>
//               </th>
//               <th scope="col">
//                 <h3 className="font-weight-bold" style={{textAlign:'center'}}>Name</h3>
//               </th>
//               <th scope="col">
//                 <h3 className="font-weight-bold" style={{textAlign:'center'}}>UserId</h3>
//               </th>
//               <th scope="col">
//                 <h3 className="font-weight-bold" style={{textAlign:'center'}}>Password</h3>
//               </th>
//               <th scope="col">
//                 <h3 className="font-weight-bold" style={{textAlign:'center'}}>View All</h3>
//               </th>

//               <th scope="col">
//                 <h3 className="font-weight-bold" style={{textAlign:'center'}}>Edit</h3>
//               </th>
//             </tr>
//           </thead>
//           <tbody>
//             {currentItems.map((item, index) => (
//               <tr key={index + 1}>
//                 <td>
//                   <h3>{index + 1}</h3>
//                 </td>
//                 <td >
//                   <h3 style={{textAlign:'center'}}>{item.name}</h3>
//                 </td>
//                 <td>
//                   <h3 style={{textAlign:'center'}}>{item.userid}</h3>
//                 </td>
//                 <td>
//                   <h3 style={{textAlign:'center'}}>{item.password}</h3>
//                 </td>
//                 <td>
//                   <h3 style={{textAlign:'center', fontSize:'25px'}}>
//                   <Link to={{ pathname: "/ViewAll-Details", state:{item}}} >
//                     <FaEye/>
//                     </Link>
//                   </h3>
//                 </td>
//                 <td>
//                   <h3 style={{ fontSize: '25px', textAlign: 'center' } }>
//                     <Link to={{ pathname: "/editUser", state:{item}}} >
//                       <FaEdit/>
//                     </Link>
//                   </h3>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//       <div className="pagination">
//         <div className="pagination-list d-flex justify-content-center rounded-pill">
//           {pageCount > 0 &&
//             generatePageNumbers().map((pageNumber) => (
//               <li key={pageNumber} className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}>
//                 <button
//                   className="page-link rounded-circle"
//                   onClick={() => handlePageChange(pageNumber)}
//                 >
//                   {pageNumber + 1}
//                 </button>
//               </li>
//             ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UserDetails;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from 'config';
import { getItem } from 'utils/localStorageController';
import { faEdit, faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FaEdit, FaEye, FaInfoCircle, FaStreetView } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const UserDetails = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(7);
  const a_token = getItem('a_token');

  const viewIncomeHistory = async () => {
    try {
      const response = await axios.get(API_URL + 'admin/userdetails.php', {
        headers: {
          Authorization: `Bearer ${a_token}`,
        },
      });

      setData(response.data);
    } catch (error) {
      console.error('Error retrieving user details:', error);
    }
  };

  useEffect(() => {
    viewIncomeHistory();
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageCount = Math.ceil(data.length / itemsPerPage);
  const startIdx = (currentPage * itemsPerPage) % data.length;
  const endIdx = (startIdx + itemsPerPage) % data.length;
  const currentItems = data.slice(startIdx, startIdx < endIdx ? endIdx : data.length);

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const range = 2;

    let start = currentPage - range;
    let end = currentPage + range;

    if (start < 0) {
      start = 0;
      end = Math.min(range * 2, pageCount - 1);
    }

    if (end >= pageCount) {
      end = pageCount - 1;
      start = Math.max(0, pageCount - range * 2 - 1);
    }

    for (let i = start; i <= end; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <div className="container mt-12">
      <h1 className="font-weight-bold text-center">User Details</h1>
      <div className="table-responsive mt-4">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">
                <h3 className="font-weight-bold" style={{ textAlign: 'center' }}>S.n</h3>
              </th>
              <th scope="col">
                <h3 className="font-weight-bold" style={{ textAlign: 'center' }}>Name</h3>
              </th>
              <th scope="col">
                <h3 className="font-weight-bold" style={{ textAlign: 'center' }}>UserId</h3>
              </th>
              <th scope="col">
                <h3 className="font-weight-bold" style={{ textAlign: 'center' }}>Password</h3>
              </th>
              <th scope="col">
                <h3 className="font-weight-bold" style={{ textAlign: 'center' }}>View All</h3>
              </th>
              <th scope="col">
                <h3 className="font-weight-bold" style={{ textAlign: 'center' }}>Edit</h3>
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={index + startIdx}>
                <td>
                  <h3>{index + startIdx + 1}</h3>
                </td>
                <td>
                  <h3 style={{ textAlign: 'center' }}>{item.name}</h3>
                </td>
                <td>
                  <h3 style={{ textAlign: 'center' }}>{item.userid}</h3>
                </td>
                <td>
                  <h3 style={{ textAlign: 'center' }}>{item.password}</h3>
                </td>
                <td>
                  <h3 style={{ textAlign: 'center', fontSize: '25px' }}>
                    <Link to={{ pathname: "/ViewAll-Details", state: { item } }} >
                      <FaEye />
                    </Link>
                  </h3>
                </td>
                <td>
                  <h3 style={{ fontSize: '25px', textAlign: 'center' }}>
                    <Link to={{ pathname: "/editUser", state: { item } }} >
                      <FaEdit />
                    </Link>
                  </h3>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <div className="pagination-list d-flex justify-content-center rounded-pill">
          {pageCount > 0 &&
            generatePageNumbers().map((pageNumber) => (
              <li key={pageNumber} className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}>
                <button
                  className="page-link rounded-circle"
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber + 1}
                </button>
              </li>
            ))}
        </div>
      </div>
    </div>
  );
};

export default UserDetails;

