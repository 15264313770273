import React from 'react'
import AppHeader from './AppHeader'
import MyProfile from 'views/Elements/MyProfile'

const Profile = () => {
  return (
    <div>
        <AppHeader/>
        <MyProfile/>
    </div>
  )
}

export default Profile
